@import '@/styles/index.less';

.menu-bar {
    justify-content: center;
    background-color: @skal-primary-white;
    margin-top: 21px;
    font-family: 'TheMix', sans-serif;

    &.horizontal {
        border: none;
        margin-top: 15px;
    }
}

.ant-menu-item {
    font-family: 'TheMix', sans-serif;
    font-size: 16px;
    height: 46px;
    border-bottom: 2px solid transparent;
    padding-bottom: 12px;
}

.ant-menu-item-selected {
    color: @skal-primary-red;
    border-bottom: 2px solid @skal-primary-red;
}

.ant-menu-horizontal, .ant-menu-item::after, .ant-menu-submenu::after {
    border: none !important;
}