@import 'antd/es/style/themes/default.less';
@import 'antd/dist/antd.less';
@import '@/styles/index.less';

#root {
  width: 100%;
  height: 100%;
}

.app-content {
  min-height: calc(100vh - @header-height - @footer-height);
}