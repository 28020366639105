@import '@/styles/index.less';

.certificate-inquiry-tabs-container {
  margin-bottom: 16px;
  display: flex;
  column-gap: 32px;

  .ant-badge-count {
    min-width: 24px;
  }

  .certificate-inquiry-tab {
    font-size: 16px;
    height: 40px;
    border-bottom: 2px solid transparent;
    padding-bottom: 12px;
  }

  .certificate-inquiry-tab.active {
    color: @skal-primary-red;
    border-bottom: 2px solid @skal-primary-red;
  }

  .certificate-inquiry-tab.disabled {
    cursor: not-allowed;
    color: @skal-disabled;
    border-bottom: 2px solid @skal-disabled;
  }
  
  .certificate-inquiry-tab.disabled:hover {
    background-color: transparent;
  }
}

@media screen and (max-width: 767px) {
  .certificate-inquiry-tabs-container {
    column-gap: 10px;
    height: fit-content;

    .certificate-inquiry-tab {
      height: inherit;
      word-wrap: break-word;
      white-space: normal;
    
      p {
        margin: 0;
      }
    }
  }
}