@import '@/styles/index.less';

.profile {
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    .operator-name {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 0 0 102px;
    }

    .profile-button{
        span {
            width: 32px;
        }
    }

    .language {
        flex: 0 0 102px;
    }
}


@media screen and (max-width: 1256px) {
    .profile {        
        .language {
            flex: 0 0 32px;
        }
    }
}

@media screen and (min-width: 1000px) {
    .profile {    
        padding: 0 33px 0 0;
    }
}

@media screen and (min-width: 100px) and (max-width: 913px) {
    .profile {    
        padding: 0 33px 0 0;
    }
}