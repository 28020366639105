@import '@/styles/index.less';

.header {
    height: @header-height;

    .logo {
        width: 198px;
        height: 28px;
        margin: 16px 24px 16px 40px;
        background: url('~@/assets/bio-certificate.svg') no-repeat center center;
        background-size: contain;
    }

    .profile-container {
        margin-top: 21px;
        text-align: right;
    }
}