@import '@/styles/index.less';

.ant-modal-body {
    padding: 32px 32px 24px 32px;
}

.error-modal {
    &__body {
        display: flex;
        flex-direction: row;
        gap: 16px;
        margin-bottom: 24px;
        font-size: 16px;

        &__icon {
            color: @skal-primary-red;
            font-size: 22px;
        }

        &__text {
            display: flex;
            flex-direction: column;

            h3 {
                margin-top: 4px;
                padding-bottom: 8px;
                line-height: 24px;
                font-weight: 600;
            }

            p {
                margin-bottom: 0px
            }

            a {
                color: @skal-primary-red
            }
        }
    }
    &__button {
        margin-left: auto;
        display: block;
        font-size: 16px;
    }
}