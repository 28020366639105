.align-middle-horizontal {
    margin-right: auto;
    margin-left: auto;
}

.full-width {
    width: 100%
}

// flex helpers

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-reserve {
    display: flex;
    flex-direction: row-reverse;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-column-reserve {
    display: flex;
    flex-direction: column-reverse;
}


// padding helpers

.pl-1 {
    padding-left: 4px;
} 

.pl-2 {
    padding-left: 8px;
} 

.pl-3 {
    padding-left: 12px;
} 

.pl-4 {
    padding-left: 16px;
} 

.pl-5 {
    padding-left: 20px;
} 

.pl-6 {
    padding-left: 24px;
}

.pl-7 {
    padding-left: 28px;
}

.pl-8 {
    padding-left: 32px;
} 

.pt-1 {
    padding-top: 4px;
} 

.pt-2 {
    padding-top: 8px;
} 

.pt-3 {
    padding-top: 12px;
} 

.pt-4 {
    padding-top: 16px;
} 

.pt-5 {
    padding-top: 20px;
} 

.pt-6 {
    padding-top: 24px;
}

.pt-7 {
    padding-top: 28px;
}

.pt-8 {
    padding-top: 32px;
} 

.pr-1 {
    padding-right: 4px;
} 

.pr-2 {
    padding-right: 8px;
} 

.pr-3 {
    padding-right: 12px;
} 

.pr-4 {
    padding-right: 16px;
} 

.pr-5 {
    padding-right: 20px;
} 

.pr-6 {
    padding-right: 24px;
}

.pr-7 {
    padding-right: 28px;
}

.pr-8 {
    padding-right: 32px;
} 

.pb-1 {
    padding-bottom: 4px;
} 

.pb-2 {
    padding-bottom: 8px;
} 

.pb-3 {
    padding-bottom: 12px;
} 

.pb-4 {
    padding-bottom: 16px;
} 

.pb-5 {
    padding-bottom: 20px;
} 

.pb-6 {
    padding-bottom: 24px;
}

.pb-7 {
    padding-bottom: 28px;
}

.pb-8 {
    padding-bottom: 32px;
} 

// margin helpers

.ml-1 {
    padding-left: 4px;
} 

.ml-2 {
    padding-left: 8px;
} 

.ml-3 {
    padding-left: 12px;
} 

.ml-4 {
    padding-left: 16px;
} 

.ml-5 {
    padding-left: 20px;
} 

.ml-6 {
    padding-left: 24px;
}

.ml-7 {
    padding-left: 28px;
}

.ml-8 {
    padding-left: 32px;
} 

.mt-1 {
    padding-top: 4px;
} 

.mt-2 {
    padding-top: 8px;
} 

.mt-3 {
    padding-top: 12px;
} 

.mt-4 {
    padding-top: 16px;
} 

.mt-5 {
    padding-top: 20px;
} 

.mt-6 {
    padding-top: 24px;
}

.mt-7 {
    padding-top: 28px;
}

.mt-8 {
    padding-top: 32px;
} 

.mr-1 {
    padding-right: 4px;
} 

.mr-2 {
    padding-right: 8px;
} 

.mr-3 {
    padding-right: 12px;
} 

.mr-4 {
    padding-right: 16px;
} 

.mr-5 {
    padding-right: 20px;
} 

.mr-6 {
    padding-right: 24px;
}

.mr-7 {
    padding-right: 28px;
}

.mr-8 {
    padding-right: 32px;
} 

.mb-1 {
    padding-bottom: 4px;
} 

.mb-2 {
    padding-bottom: 8px;
} 

.mb-3 {
    padding-bottom: 12px;
} 

.mb-4 {
    padding-bottom: 16px;
} 

.mb-5 {
    padding-bottom: 20px;
} 

.mb-6 {
    padding-bottom: 24px;
}

.mb-7 {
    padding-bottom: 28px;
}

.mb-8 {
    padding-bottom: 32px;
} 