@import '@/styles/index.less';

.footer {
    background-color: @skal-primary-red;
    width: 100%;
    height: @footer-height;
    color: @skal-primary-white;

    .logo-container {
        max-width: 358px;
        width: 50vw;

        .logo {
            width: 100%;
            aspect-ratio: 7/1;
            margin-top: 52px;
            background: url('~@/assets/logo_inversed.svg');
            background-repeat: no-repeat;
            background-size: contain;  
        }
    }

    a {
        margin-left: 12px;        
    }

    .info {
        margin-top: 28px;
        width: 380px;
        height: 27px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        gap: 46px;
    }
}