@import '@/styles/index.less';

.certificate-detail-alert {
    padding: 9px 16px;
    max-width: 954px;
    margin-left: auto;
    margin-right: auto;

    &__message {
        font-size: 16px;
        padding-left: 10px;
        padding-right: 16px;

        a {
            color: @skal-primary-red;
        }
    }

    &__button {
        font-size: 16px;
    }
}