/* ------------
 *
 *  THE MIX
 * 
 * ------------ */
 @font-face {
    font-family: "TheMix";
    src: url("./assets/fonts/subset-TheMix-SemiLight.woff2") format("woff2"), url("./assets/fonts/subset-TheMix-SemiLight.woff") format("woff");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "TheMix";
    src: url("./assets/fonts/subset-TheMix-SemiLightItalic.woff2") format("woff2"), url("./assets/fonts/subset-TheMix-SemiLightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: "TheMix";
    src: url("./assets/fonts/subset-TheMix-Regular.woff2") format("woff2"), url("./assets/fonts/subset-TheMix-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "TheMix";
    src: url("./assets/fonts/subset-TheMix-RegularItalic.woff2") format("woff2"), url("./assets/fonts/subset-TheMix-RegularItalic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: "TheMix";
    src: url("./assets/fonts/subset-TheMix-SemiBold.woff2") format("woff2"), url("./assets/fonts/subset-TheMix-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "TheMix";
    src: url("./assets/fonts/subset-TheMix-SemiBoldItalic.woff2") format("woff2"), url("./assets/fonts/subset-TheMix-SemiBoldItalic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: "TheMix";
    src: url("./assets/fonts/subset-TheMix-Bold.woff2") format("woff2"), url("./assets/fonts/subset-TheMix-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: "TheMix";
    src: url("./assets/fonts/subset-TheMix-BoldItalic.woff2") format("woff2"), url("./assets/fonts/subset-TheMix-BoldItalic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: "TheMix";
    src: url("./assets/fonts/subset-TheMix-ExtraBold.woff2") format("woff2"), url("./assets/fonts/subset-TheMix-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: "TheMix";
    src: url("./assets/fonts/subset-TheMix-ExtraBoldItalic.woff2") format("woff2"), url("./assets/fonts/subset-TheMix-ExtraBoldItalic.woff") format("woff");
    font-weight: 800;
    font-style: italic;
  }
  @font-face {
    font-family: "TheMix";
    src: url("./assets/fonts/subset-TheMix-Black.woff2") format("woff2"), url("./assets/fonts/subset-TheMix-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: "TheMix";
    src: url("./assets/fonts/subset-TheMix-BlackItalic.woff2") format("woff2"), url("./assets/fonts/subset-TheMix-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
  }
  /* ------------
   *
   *  THE SANS
   * 
   * ------------ */
  @font-face {
    font-family: "TheSans";
    src: url("./assets/fonts/subset-TheSans-SemiBold.woff2") format("woff2"), url("./assets/fonts/subset-TheSans-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "TheSans";
    src: url("./assets/fonts/subset-TheSans-SemiLight.woff2") format("woff2"), url("./assets/fonts/subset-TheSans-SemiLight.woff") format("woff");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "TheSans";
    src: url("./assets/fonts/subset-TheSans-SemiLightItalic.woff2") format("woff2"), url("./assets/fonts/subset-TheSans-SemiLightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: "TheSans";
    src: url("./assets/fonts/subset-TheSans-SemiBoldItalic.woff2") format("woff2"), url("./assets/fonts/subset-TheSans-SemiBoldItalic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: "TheSans";
    src: url("./assets/fonts/subset-TheSans-Regular.woff2") format("woff2"), url("./assets/fonts/subset-TheSans-Regular.woff") format("woff");
    font-weight: 500;
    font-style: normal;
  }
  /* ------------
   *
   *  ICON FONT
   * 
   * ------------ */
  @font-face {
    font-family: "skal";
    src: url("./assets/fonts/skal.woff2") format("woff2"), url("./assets/fonts/skal.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
