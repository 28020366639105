body {
  font-family: 'TheSans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: @skal-primary-black-eightyfive;
}

ul {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
}

a {
  color: @skal-primary-white;
}

h1 {
  font-family: 'TheMix', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 50px;
  color: @skal-primary-black-eightyfive;
}

h5 {
  font-family: 'TheMix', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: @skal-primary-black-eightyfive;
}

h6 {
  font-family: 'TheMix', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: @skal-primary-black-eightyfive;
}

th {
  color: @skal-primary-black-eightyfive !important;
  font-weight: bold !important;
}

td {
  color: @skal-primary-black-eightyfive;
}