
.certificate-inquiry-requested-table-colums-actions {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.certificate-inquiry-requested-table-colums-divider-container {
  display: flex;
  align-items: center;
}
.certificate-inquiry-requested-table-colums-divider {
  width: 1px;
  height: 12px;
  background: rgba(0, 0, 0, 0.06);
}