.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 24px 16px 24px;
    margin-top: 64px;
    gap: 16px;
    text-align: center;


    .not-found-image-container {
        width: 100vw;
        height: 450px;
        max-width: 650px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .not-found-image {
            width: 100%;
            height: 100%;
            background: url('~@/assets/not-found.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}