@import '@/styles/index.less';

.message {
  padding-top: 300px;
  margin: 0;
  text-align: center;

  &.loader {
    font-size: 42px;
    padding-top: 300px;
  }

  &.error {
    color: @skal-primary-red;
  }
}

.certificate-detail {
  max-width: 1200px;
  margin: 0 auto;
  padding: 86px 0;

  &__header {
    position: relative;
  }

  &__back-button {
    position: absolute;
    left: 0;
  }

  &__title {
    margin-bottom: 37px;
    padding: 0 30px;
    line-break: auto;
  }

  &__back-button span:nth-of-type(2) {
    font-size: 20px;
  }

  &__download-button {
    background-color: @skal-primary-red;
    height: 32px;
    color: @skal-primary-white;
    cursor: pointer;
    text-align: right;
    padding: 7px 15px;
    border-radius: 2px;

    .download-text {
      margin-left: 12px;
    }

    .anticon-download {
      font-size: 14px;
    }

    &.disabled {
      background-color: @skal-disabled;
      cursor: default;
    }
  }

  .bio-certificate {
    border: 1px solid @skal-neutral-four;
    border-radius: 8px;

    &__info {
      display: flex;
      flex-direction: column;
      padding: 32px 24px 24px 24px;
      width: 465px;
      gap: 8px;

      &__name {
        color: @skal-primary-black-eightyfive;
        font-size: 22px;
        line-height: 26.4px;
      }

      &__skal-number {
        color: @skal-primary-black;
        text-align: right;

        &--align-left {
          text-align: left;
        }
      }

      &__tradeNames {
        margin: 0;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &__postalCode,
      &__address {
        color: @skal-primary-black-fortyfive;
      }

      &__address {
        margin-top: 8px;
      }
    }

    &__footer {
      padding: 16px 24px;

      &--align-left {
        gap: 8px;
        flex-direction: column-reverse;
        align-items: start;
      }

      &__dates {
        display: flex;
        flex-direction: column;
        font-family: 'TheMix', sans-serif;
        font-weight: 600;
        justify-content: flex-end;
        gap: 8px;

        &--align-left { justify-content: start; }

        &--valid-from,
        &--valid-until {
          width: 100%;
          margin: 0;
          text-align: right;
        }

        &--valid-from { color: @skal-primary-black-fortyfive; }
        &--valid-until { color: @skal-primary-black; }

        &--align-left.bio-certificate__footer__dates--valid-from,
        &--align-left.bio-certificate__footer__dates--valid-until {
          text-align: left;
        }
      }
    }
  }
}

@media screen and (max-width: 1240px) {
  .certificate-detail {
    padding: 32px 12px;
  }
}

@media screen and (max-width: 576px) {
  .bio-certificate {
    max-width: calc(100% - 24px);

    &__info {
      width: unset !important;
    }
  }
}