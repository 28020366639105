@import '@/styles/index.less';

.table-filters {
  max-width: 90vw;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.certificate-inquiry-requested-table {
  white-space: pre-line;

  a {
    color: @skal-primary-red;
  }

  td, th {
    color: @skal-primary-black;
    font-size: 16px;
  }

  th.ant-table-cell {
    color: @skal-primary-black !important;
  }
}

.anticon-search {
  svg path {
    fill: @skal-primary-white;
  }
}

.ant-empty-description {
   color: @skal-neutral-seven
}

.ant-pagination-prev, .ant-pagination-next {
  min-width: unset;
  width: 24px;
  height: 24px;
}

.ant-pagination-item, .ant-pagination-item-link {
  border: unset !important;
  min-width: unset;
  line-height: 21px;
  width: 24px;
  height: 24px;

  a {
    color: @skal-primary-black !important;
  }
}

.ant-pagination-item-active {
  border: 1px solid @skal-primary-red !important;
  a {
    color: @skal-primary-red !important;
  }
}

.ant-input-affix-wrapper {
  height: 40px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ant-input-group-addon {
  width: 46px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

@media screen and (max-width: 1320px ) {
  .certificate-inquiry-requested-table {
    max-width: 90vw;
    margin: 10px auto;
  }

  .certificates-inquiry-requested-table-search {
    display: flex;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
}
