@import '@/styles/index.less';

.empty-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .grey-text-color {
    color: #8C8C8C;
  }
}

.share-certificate-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.share-certificate-table {
  a {
    color: @skal-primary-red;
  }

  white-space: pre-line;
}

.close-modal-button {
  background: @skal-primary-red;
  color: @skal-primary-white;
  font-weight: 300;
}

.open-modal-button {
  height: 40px;
}

.empty-image {
  width: 106px;
  height: 100px;
  background: url('~@/assets/empty-img-default.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.share-certificate-modal-wrapper {
  .ant-modal-title {
    font-size: 18px;
    font-weight: 600;
  }

  .ant-table-column-title {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-table-cell {
    font-size: 16px;
  }
}
