@import '@/styles/index.less';

.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 86px 0 0 0;

    h1 {
        text-align: center;
        margin-bottom: 36px;
    }
}

#superset-container {
    height: 100vh;
    
    iframe {
        width: 100%;
        height: 100%;
        border: 0;
    }
}