@import '@/styles/index.less';

.certificate-inquiry-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 86px 0;

    h1 {
        text-align: center;
        margin-bottom: 36px;
    }

    .certificate-inquiry-tabs {
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-badge-count {
            background-color: @skal-neutral-four;
            color: @skal-primary-black-fortyfive;
        }

        .active {
            .ant-badge-count {
                background-color: @skal-primary-red-light-3;
                color: @skal-primary-red;
            }
        }
    }
}
